<template>
  <div @mouseenter="showTitle">
    <swiper
      v-if="images"
      class="swiper"
      :navigation="isDesktop ? isSlider : false"
      :pagination="
        isDesktop
          ? showPagination
          : {
              clickable: true,
            }
      "
      :modules="modules"
      :centered-slides="true"
      loop="true"
      initialSlide="1"
      :autoplay="{
        delay: 3000,
        waitForTransition: true,
      }"
      @update="swiperUpdate"
    >
      <swiper-slide v-for="(slide, i) in images" :key="i">
        <div v-if="slide?.url">
          <a :href="slide?.url?.indexOf('http') === 0 ? slide?.url : `http://${slide?.url}`" target="_blank" rel="noopener">
            <div class="slide_content" :style="{ 'background-image': `url(${slide.stripe})` }">
              <div class="slide_image flex justify-center">
                <img :src="slide.imageLink ?? slide" class="slide_image-image" :title="slide.text || 'Hier klicken'" :alt="slide.text || 'Hier klicken'"/>
              </div>
            </div>
          </a>
        </div>
        <div v-else>
          <div class="slide_content" :style="{ 'background-image': `url(${slide.stripe})` }">
            <div class="slide_image flex justify-center">
              <img :src="slide.imageLink ?? slide" class="slide_image-image"  :title="slide.text || 'Hier klicken'" :alt="slide.text || 'Hier klicken'" />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
  import { useStore } from "vuex";
  import { computed, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
  import "swiper/swiper-bundle.css";
  import { Navigation, Pagination, Autoplay } from "swiper";

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },

    setup(props) {
      const store = useStore();
      const route = useRoute();
      const paybackCarousel = ref(null);
      const stripeLink = ref("");
      const autoPlaying = ref({});
      const slide = ref();
      const isSlider = ref(true);
      const mediaQuery = "(min-width: 768px)";
      const mediaQueryList = window.matchMedia(mediaQuery);
      let isDesktop = ref(mediaQueryList.matches);
      const showTitle = () => {
        Array.from(document.querySelectorAll(".vueperslide")).forEach((slider) => {
          const title = slider?.querySelector(".vueperslide__title")?.innerText;
          if (title) {
            slider.title = slider.querySelector(".vueperslide__title").innerText;
          }
        });
      };

      const swiperUpdate = (swiper) => {
        if (swiper.slides.length === 1 || Object.keys(images.value).length <= 1) {
          swiper.autoplay.stop();
          swiper.stopOnLastSlide = true;
        } else {
          swiper.autoplay.start();
          swiper.stopOnLastSlide = false;
        }
      };

      const logEvents = (state, ev) => {
        stripeLink.value = ev.nextSlide.content;
      };
      const redirectToUrl = (url) => {
        url ? window.location.assign(url) : "";
      };

      const images = computed(() => {
        return store.getters["sliderImages/getImages"];
      });

      const setDefaultBackground = (images) => {
        stripeLink.value = images[0]?.stripe;
      };
      watch(
        () => props.modelValue,
        () => {
          paybackCarousel.value.slideTo(props.modelValue);
        }
      );
      watch(
        () => Object.keys(images.value).length,
        async (current, prev) => {
          if (current != prev) {
            setDefaultBackground(images.value);
            if (Object.keys(images.value).length <= 1 || (route.path.indexOf("praemien") >= 0 && route.path.indexOf("produkt") >= 0)) {
              isSlider.value = false;
            } else isSlider.value = true;
          }
        }
      );

      return {
        modules: [Navigation, Pagination, Autoplay],
        paybackCarousel,
        images,
        redirectToUrl,
        showTitle,
        stripeLink,
        logEvents,
        setDefaultBackground,
        autoPlaying,
        slide,
        isSlider,
        swiperUpdate,
        isDesktop,
      };
    },
    props: {
      thumbnails: Array,
      showPagination: Object,
      modelValue: { type: Number, default: 0 },
      fixedHeight: { type: Number, default: 480 },
      title: { type: String },
      breakpoints: {
        767: {
          fixedHeight: 480,
          //desktop version 480px
        },
      },
    },
  };
</script>

<style lang="postcss">
  .slider {
    z-index: 1;
  }
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      color: white;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 0;
      -webkit-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      z-index: 1;
      color: rgba(0, 0, 0, 0);
      outline: none;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      cursor: pointer;
      background-size: 100%;
      background-image: url("../../assets/img/arrowBtn.svg");
      background-repeat: no-repeat;
    }
    .swiper-button-prev {
      background-position: 0px -500px;
      &:hover {
        background-position: 0px -550px;
      }
    }
    .swiper-button-next {
      background-position: 0px -400px;
      &:hover {
        background-position: 0px -450px;
      }
    }

    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after,
    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
      content: "";
    }
  }

  .product .swiper-wrapper {
    height: 420px;
  }

  .sliderHeader .swiper-slide .slide_content {
    height: 260px;

    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
    .slide_image {
      @apply relative w-full;
      height: 260px;
      width: 100vw;
      overflow: hidden;
      &-image {
        @apply absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2000px;
        max-width: 2000px;
        height: 260px;
        zoom: 1;
      }
      @media screen and (max-width: 850px) {
        &-image {
          zoom: 0.8;
        }
      }
      @media screen and (max-width: 767px) {
        height: 160px;
        &-image {
          width: 700px;
          max-width: 700px;
          height: 160px;
          zoom: 1;
        }
      }
    }
  }

  .product .sliderHeader .swiper-slide .slide_content {
    .slide_image {
      @media screen and (max-width: 850px) {
        &-image {
          zoom: 0.8;
          max-width: 85%;
          margin: auto;
          height: auto;
        }
      }
      @media screen and (max-width: 767px) {
        height: 160px;
        &-image {
          width: 700px;
          max-width: 85%;
          margin: auto;
          height: auto;
          zoom: 0.8;
        }
      }
    }
  }

  .product .swiper-wrapper {
    position: relative;
    align-items: center;
    ovweflow: hidden;
  }

  .sliderHeader .swiper-wrapper {
    height: 260px;
    width: auto;
    position: relative;
    align-items: center;
    ovweflow: hidden;

    .slide_content {
      height: 260px;
      width: auto;
      justify-content: center;
      align-content: center;
      display: grid;
    }
  }

  @media (max-width: 768px) {
    .sliderHeader .swiper-wrapper {
      height: 200px;
    }
    .sliderHeader .swiper-wrapper .slide_content {
      height: 200px;
    }
  }

  @media (max-width: 460px) {
    .product .swiper-wrapper {
      height: 320px;
    }
    .sliderHeader .swiper-wrapper {
      height: 160px;
    }
    .sliderHeader .swiper-wrapper .slide_content {
      height: 160px;
    }
  }

  @media (max-width: 320px) {
    .product .swiper-wrapper {
      height: 280px;
    }
    .sliderHeader .swiper-wrapper {
      height: 160px;
    }
    .sliderHeader .swiper-wrapper .slide_content {
      height: 160px;
    }
  }

  .carousel {
    background: url("../../assets/img/background/teaser_bg.jpg") no-repeat;
    background-size: 100% 100%;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: 767px) {
      min-height: 160px;
      width: 760px !important;
    }

    &__slide {
      min-height: 250px;

      &-item {
        width: 100% !important;
        img {
          min-height: 250px;
        }
      }
    }
  }
  .product .carousel {
    background: #fff;
  }
  .vueperslides__arrow {
    @apply text-blue;
    svg {
      @apply w-10;
    }
  }
  .carousel__prev {
    left: 28px;
  }
  .carousel__next {
    right: 28px;
  }
  .vueperslide__title {
    display: none !important;
  }
</style>
